<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row title-row">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <h4 class="card-title mb-4">Support Category List</h4>
              </div>
              <!--col-xl-6 col-lg-6 col-md-6--->

              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="add-btn-box">
                  <span v-b-modal.modal-center @click="form = {}" class="addbtn"
                    >Add Category</span
                  >
                </div>
              </div>
              <!--col-xl-6 col-lg-6 col-md-6-->
            </div>

            <simplebar style="max-height: 600px">
              <div class="table-responsive">
               
                <b-table
                  :items="myProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <template #empty>
                    <div class="d-flex justify-content-center mb-3">
                      No Record Found
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <!-- <strong>Loading...</strong> -->
                    </div>
                  </template>

                  <template v-slot:cell(id)="data">
                    {{ data.item.id }}</template
                  >
                  <template v-slot:cell(name)="data">
                    {{ data.item.name }}</template
                  >
                  <template v-slot:cell(created_by)="data">
                    {{ data.item.user.name }}</template
                  >
                  <template v-slot:cell(created_at)="data">
                    {{
                      new Date(data.item.created_at).toLocaleDateString()
                    }}</template
                  >
                  <template v-slot:cell(updated_at)="data">
                    {{
                      new Date(data.item.updated_at).toLocaleDateString()
                    }}</template
                  >

                  <template v-slot:cell(action)="data">
                    <span v-b-modal.modal-center @click="form = data.item"
                      ><i class="far fa-edit text-primary mx-3 action"></i
                    ></span>
                    <span @click="deletecategory(data.item.id)"
                      ><i class="far fa-trash-alt text-danger action"></i
                    ></span>
                  </template>
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for assigning token to vendor -->
    <b-modal
      id="modal-center"
      size="md"
      centered
      :title="
        Object.keys(form).length === 0
          ? 'Add Support Category'
          : 'Edit Support Category'
      "
      title-class="font-18"
      hide-footer
      class="padding_modal"
    >
      <b-form @submit="onSubmit($event, form.id)">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Category Name</label
          >
          <input
            type="text"
            v-model="form.name"
            class="form-control"
            id="exampleFormControlInput1"
          />
          <div class="error" v-if="submitted && !$v.form.name.required">
            Name is required
          </div>
        </div>

        <div class="mt-5 text-center">
          <button type="submit" class="btn btn-outline-info px-5">
            <span v-if="this.formloading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>
              Submit
            </span>
          </button>
        </div>
      </b-form>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

/**
 * Lending component
 */
export default {
  page: {
    title: "Support Category",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
  },
  data() {
    return {
      title: "Support Category",
      items: [
        {
          text: "Support Category",
          href: "#",
        },
        {
          text: "Support",
          active: true,
        },
      ],
      loading: false,
      formloading: false,

      form: {
        name: "",
      },
      submitted: false,

      totalRows: 0,
      currentRowData: "",
      currentPage: 1,
      perPage: 2,
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "id",
          value: "id",
          sortable: false,
        },
        {
          key: "name",
          value: "name",
          sortable: false,
        },
        {
          key: "created_by",
          value: "created_by",
          sortable: false,
        },
        {
          key: "created_at",
          value: "created_at",
          sortable: false,
        },
        {
          key: "updated_at",
          value: "updated_at",
          sortable: false,
        },
        {
          key: "action",
          value: "action",
          sortable: false,
        },
      ],
    };
  },

  validations: {
    form: {
      name: { required },
    },
  },

  methods: {
    async check(e) {
      var status = e.status ? 1 : 0;
      var result = await ApiClass.updateFormRequest(
        "admin/broker/update_status/" + e.id,
        true,
        { status: status }
      );
      if (result.data.status_code == "1") {
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    async onSubmit(event, id) {
      event.preventDefault();
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.formloading = true;
      var call_api = id
        ? "admin/ticket_type/update/" + id
        : "admin/ticket_type/create";
      var param = {
        name: this.form.name ?? "",
      };

      var result = id
        ? await ApiClass.updateFormRequest(call_api, true, param)
        : await ApiClass.postRequest(call_api, true, param);

      if (result.data.status_code == "1") {
        this.formloading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
        this.$refs.table.refresh();
        this.$root.$emit("bv::hide::modal", "modal-center");
      } else {
        this.formloading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    deletecategory(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          ApiClass.deleteRequest("admin/ticket_type/delete/" + id, true);
          Swal.fire("Deleted!", "Category has been deleted.", "success");
          this.$refs.table.refresh();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Cancelled", "Category still in our system. :)", "error");
        }
      });
    },

    async myProvider(ctx, callback) {
      this.loading = !this.loading;
      await ApiClass.getRequest("admin/ticket_type/get", true)
        .then((res) => {
          if (res.data.status_code == 1) {
            this.loading = !this.loading;
            var items = res.data.data.data ? res.data.data.data : [];
            this.currentPage = res.data.data ? res.data.data.current_page : "";
            this.totalRows = res.data.data ? res.data.data.total : "";
            callback(items);
          }
        }).bind(this)
    },

    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
  },
};
</script>

<style scoped>
.action {
  font-weight: 600;
  cursor: pointer;
}

.add-btn-box {
  text-align: right;
}

.add-btn-box span.addbtn {
  background-color: #556ee6;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
}
</style>
